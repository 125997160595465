<!--设置管理导航页-->
<template>
  <div id="Home">
    <div class="header">
      <img src="../../../public/images/set/setindex08.png" />
      <span>{{$t('sidebar.d1')}}</span>
    </div>
    <div class="main">
      <div class="main-vessel">
        <div
          class="card"
          v-for="(item, index) in list"
          :key="index"
          @click="goIndex(item)"
        >
          <img :src="item.imgsrc" />
          <!-- <img v-if="item.value===9" style="width:60px;" :src="item.imgsrc" /> -->
          <span class="fontstyle">{{ item.name }}</span>
        </div>
        <div class="empty"></div>
        <div class="empty"></div>
        <div class="empty"></div>
        <div class="empty"></div>
        <div class="empty"></div>
        <div class="empty"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          value: 1, //客户中心
          name: this.$t("setHome.a1"),
          imgsrc: require("../../../public/images/set/setindex01.png"),
        },
        {
          value: 2, //用户设置
          name: this.$t("setHome.a2"),
          imgsrc: require("../../../public/images/set/setindex02.png"),
        },
        {
          value: 3, //通知设置
          name: this.$t("setHome.a3"),
          imgsrc: require("../../../public/images/set/setindex03.png"),
        },
        // {
        //   value: 4, //卡号管理
        //   name: this.$t("setHome.a4"),
        //   imgsrc: require("../../../public/images/set/setindex05.png"),
        // },
        {
          value: 5, //控制器管理
          name: this.$t("setHome.a5"),
          imgsrc: require("../../../public/images/set/setindex06.png"),
        },
        {
          value: 6, //警报管理
          name: this.$t("setHome.a6"),
          imgsrc: require("../../../public/images/set/setindex07.png"),
        },
        // {
        //   value: 7, //监控中心
        //   name: this.$t("setHome.a7"),
        //   imgsrc: require("../../../public/images/set/setindex04.png"),
        // },
        {
          value: 9, //监控中心
          name: this.$t('userOperationHistory.a1'),//'操作记录',
          imgsrc: require("../../../public/images/set/setindex05.png"),
        },
      ],
      baseInfo: {},
    };
  },
  methods: {
    // 跳转卡片对应主页
    goIndex(val) {
      let value = val.value;
      let name = val.name;
      
      switch (value) {
        case 1:
          this.$router.push({
            path: "/set/customerCenter",
          });
          break;
        case 2:
          this.$router.push({
            path: "/set/userSettings",
          });
          break;
        case 5:          
          this.$router.push({
            path: "/set/controller/controllerManagement",
          });
          break;
          case 9:          
          this.$router.push({
            path: "/set/userOperationHistory",
          });
          break;
      }
      switch (name) {
        case this.$t("setHome.a6"):
          this.$router.push({
            path: "/homepage/management/warning/alarm",
            query: { baseInfo: this.baseInfo },
          });
          break;
        case this.$t("setHome.a3"):
          this.$router.push({
            path: "/homepage/management/notificationSetting",
            query: { baseInfo: this.baseInfo },
          });
          break;
        // case this.$t("setHome.a5"):
        //   console.log("goIndex controllerManagement:",name);
        //   this.$router.push({
        //     path: "/homepage/controller/controllerManagement",
        //     query: { baseInfo: this.baseInfo },
        //   });
        //   break;
      }
    },
  },
};
</script>

<style scoped>
#Home {
  width:100%;
  height: 100%;
}
.header {
  height: 60px;
  padding: 0px 20px 0px 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}
.header span {
  margin-left: 20px;
  font-size: 20px;
}
.main {
  height: 100%;
  padding: 20px 20px 0px 20px;
  overflow: auto;
}
.main-vessel {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card {
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #fff;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid rgba(12, 12, 12, 0.1);
  transition: 200ms;
}
.card:hover {
  border: 1px solid #7682ce;
  box-shadow: 0 15px 30px #bdbcbc;
}
.fontstyle {
  font-size: 16px;
  margin-top: 25px;
}
.empty {
  width: 180px;
  height: 2px;
}
</style>